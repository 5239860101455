//* cinzel-regular - latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/cinzel-v11-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/cinzel-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/fonts/cinzel-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/fonts/cinzel-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('/fonts/cinzel-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/fonts/cinzel-v11-latin-regular.svg#Cinzel') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* cinzel-500 - latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/cinzel-v11-latin-500.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/cinzel-v11-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/fonts/cinzel-v11-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/fonts/cinzel-v11-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('/fonts/cinzel-v11-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/fonts/cinzel-v11-latin-500.svg#Cinzel') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* cinzel-600 - latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/cinzel-v11-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('/fonts/cinzel-v11-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/fonts/cinzel-v11-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/fonts/cinzel-v11-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('/fonts/cinzel-v11-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/fonts/cinzel-v11-latin-600.svg#Cinzel') format('svg');
  /* Legacy iOS */
  font-display: swap;
}


/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('/fonts/poppins-v15-latin-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/poppins-v15-latin-300.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/poppins-v15-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}


/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('/fonts/poppins-v15-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/poppins-v15-latin-500.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('/fonts/poppins-v15-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/poppins-v15-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('/fonts/poppins-v15-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/fonts/poppins-v15-latin-700.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}