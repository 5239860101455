html {
  //font-size: 16px;
  line-height: 1.5;
  font-family: var(--copy-font);
  color: var(--font-color);
  font-weight: 300;
  hyphens: auto;

  @media (min-width: 576px) {}

  @media (min-width: 768px) {}

  @media (min-width: 992px) {}

  @media (min-width: 1200px) {
    font-size: 18px;
  }

  @media (min-width: 1600px) {
    //font-size: 20px;
  }

}

h1,
h2,
h3,
h4,
h5,
h6,
.first-heading,
.second-heading,
.third-heading,
.fourth-heading,
.first-heading,
.sixth-heading {
  hyphens: manual;
}

h1,
.first-heading {
  //color: var(--weiss);
  //font-size: 2.2em;
  font-size:var(--step-5);
  line-height: 1.3;
  font-weight: 800;

  @media (min-width: 992px) {
    //font-size: 5em;
  }

  @include media-breakpoint-up(lg) {
    //font-size: 5em;
  }

  svg {
    flex-shrink: 0;
    height: 1.3em;
    width: 1.3em;
    position: relative;
    margin-right: .2em;
  }
}

h2,
.second-heading {
  font: 1.6em/1.3 var(--headline-font);
  text-transform: capitalize;
  font-size: 1.4em;
  line-height: 1.3;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0em;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(sm) {
    font-size: 1.7em;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.8em;
  }
}

h3,
.third-heading {
  font: var(--step-2)/1.3 var(--headline-font);
  line-height:1.3;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: var(--space-s);
  margin: 0;
  text-align: left;

  @include media-breakpoint-up(md) {
    //font-size: 1.6em;
    font-weight:400;
  }
}

// mehr abstand in fliesstexten
.content ul+h3,
.content p+h3,
.content p+.third-heading {
  margin-top: var(--space-xl);
}


h4,
.fourth-heading {
  font-family: var(--headline-font);
  font-size: 1.2em;
  line-height: 1.2;
  font-weight: 500;
  padding-bottom: 0.5em;
  margin-bottom: 0;
  margin-top: .67em;
  //color: var(--blue-darker);

  @include media-breakpoint-up(sm) {
    font-size: 1.4em;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.65em;
  }
}

/* h5,
.fifth-heading {
  font-family: var(--headline-font);
  font-size: 1.1em;
  line-height: 1.2;
  font-weight: 500;
  padding-bottom: 0.5em;
  margin-bottom: 0;
  margin-top: .67em;
  //color: var(--blue-darker);

  @include media-breakpoint-up(sm) {
    font-size: 1.1em;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.3em;
  }
}
 */

.dachzeile {
  //padding-bottom: .2em;
  //padding-top: 15px;
  font-weight: 600;
  color: var(--dark-red);

  @include media-breakpoint-up(sm) {}
}

p {
  margin: 0 0 var(--space-s) 0;
  padding: 0;
  font-size:var(--step-0);
}

ul {
  margin: 0;
  padding: 0;
}

li {
  hyphens: auto;
  padding: 0 0 0.2em 0em;
  margin: 0 0 0.2em 1.2em;
}

a {
  color: var(--font-color);
  font-size:var(--step-0);
  text-decoration: underline;
  text-decoration-color: var(--dark-red);
  text-decoration-thickness: 2px;
}


.more {
  color: var(--font-color);
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .2em;
  display: inline-block;

  &:after {
    content: "";
    width: 1em;
    height: .7em;
    margin-left: 0.2em;
    display: inline-block;
    background: url(../images/arrow.svg) no-repeat center;
    background-size: contain;
  }

  &:hover {
    color: var(--dark-red);

    &:after {
      filter: hue-rotate(504deg) brightness(225%) saturate(274%);
    }
  }
}

a.btn {
  display: inline-flex;
  font-size:var(--step-0);
  padding: 0.8em 2.4em;
  border: 1px solid var(--blue-dark);
  background: var(--blue-dark);
  border-radius: 4px;
  color: var(--blue-light-font);
  font-weight: 800;
  align-items: center;
  margin: 1em 0;
  text-decoration: none;

  &--hollow {
    border-color: var(--blue-light-font);
    color: var(--blue-light-font);
    background: transparent;
  }
}

figure {
  margin: 0 0 1em 0;
  padding: 0;
}

figcaption {
  padding: .5em 0 1em 0;
  font-weight: 300;
  //font-size: .8em;
  font-size:var(--step--1);
  color: var(--mittelgrau);

  @include media-breakpoint-up(sm) {
    padding: 1em 0 1em 0;
    //font-size: .9em;
  }

}

blockquote {
  margin: 0;
  padding: 0;

  p {
    font-size: 1.3em;
    line-height: 1.2;
    font-weight: 900;
    color: var(--yellow);

    @include media-breakpoint-up(sm) {
      font-size: 2em;
    }
  }

  .author {
    font-size: 1rem;
    line-height: 1;
    display: block;
    margin: 2em 0 1em;
  }
}