// teaser boxes at the end of documents


// Startseite Big-Teaser

.teaser {

  grid-column: 2 / 14;

  @include media-breakpoint-up(md) {
    grid-column: 2 / 14;
  }
  @include media-breakpoint-up(sm) {
    display: grid;
  }

  grid-template-columns: 32% 32% auto;
  grid-gap: var(--space-l);
  margin-bottom: var(--space-s);

  @include media-breakpoint-up(xl) {
    grid-template-columns: 35.5% 35.5% auto;
  }

  &__item {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--linien-grau);
  }

  @include media-breakpoint-up(sm) {

    &__column-1,
    &__column-2 {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: calc(var(--space-s) * -1);
        height: 100%;
        border-left: 1px solid var(--linien-grau);
      }
    }

    &__item:last-child {
      border-bottom: none;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    margin-bottom:var(--space-s);
  }

  &__text {
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;

    @include media-breakpoint-up(sm) {
      padding: 2em;
    }

    p {
      flex-grow: 1;
    }
  }

}

@include media-breakpoint-up(md) {
  .big-teaser__text {
    padding-right: 20%;
  }
}

// mobile only 

@include media-breakpoint-down(sm) {
  .teaser__item {
    border-bottom: 1px solid var(--linien-grau);
  }
}