// Le Main-navigation MOBILE

@include media-breakpoint-down(sm) {

  body.open {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

  .header__navigation {
    position: fixed;
    outline: 0;
    padding: 0;
    left: 100vw;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    text-align: left;
    transition: all .4s ease-in-out;
    background: var(--himbeer);
    opacity: 0;

    &.active {
      display: block;
      opacity: 1;
      left: 0;
    }

    .nav-inner {
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
    }

    nav {
      flex: 1 1 auto;
      display: block;
      overflow: auto;
      margin-top: 4.4em;
      padding: 0 8px 0 15px;

      // Ebene 1

      ul {
        text-align: left;
        margin-top: 1em;
        padding: 0 0 0 20px;
        flex: 1 1 auto;
        max-width: 100%;
        width: 100%;

        li {
          display: block;
          border-right: 0;
          border-radius: 0;
          border-bottom: 1px solid var(--dunkelgrau);
          position: relative;
          margin: 0 0 .5em 0;
          padding: 0;
          color: var(--blue-light-font);

          &:hover {
            color: var(--weiss);
          }

          a,
          strong {
            color: inherit;
            color: white;
            text-decoration: none;
            font-weight: 300;
            border-bottom: 2px solid rgba(0, 0, 0, 0);
            padding: 0.2em 0 0 0;
            margin: 0;
            font-size: 2em;
            line-height: 1.4;
            display: inline-block;

          }

          &.active a {
            border-color: white;
          }

          .more {
            width: 2.4em;
            height: 2.4em;
            padding: 0;
            margin: 0;
            cursor: pointer;
            z-index: 212;
            position: absolute;
            top: 0.3em;
            right: 0.1em;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: transform .3s ease-in-out;

            svg {
              height: 20px;
              width: 20px;
            }
          }

          &.hover>.more,
          &.active>.more,
          &.open>.more {
            transform: rotate(90deg);
          }

          &.open.hover>.more {
            transform: rotate(0);
          }

          &:hover,
          &.active>strong,
          &.open>a {
            color: var(--weiss);
          }



          // the login link

          &.login {

            a {
              border-color: transparent;
              color: white;
              border: 1px solid white;
              outline: 4px solid rgba(255, 255, 255, 0.15);
              margin: 1.6em 0;
              padding: 0.2em 0.7em;
              border-radius: 1px;
              font-weight: 600;
              font-size: 1.6em;

              &:hover {
                background: var(--himbeer);
              }
            }
          }

          // Ebene 2

          ul {
            margin-top: 0;
            padding: 0 0 0 1em;
            border-bottom: none;
            border-top: none;
            position: relative;
            display: none;

            li {
              margin: 0;
              padding: 0;
              border: none;

              a,
              strong {
                font-weight: 500;
                padding: 0.5em 0;
                margin: 0;
                display: flex;
                border: none;
                text-align: left;
                font-size: 1.3em;
                align-items: center;

                svg {
                  height: 1.6em;
                  width: 1.6em;
                  flex: 0 0 2.3em;
                }

                &:hover {
                  color: white;
                }
              }

              &:last-child a,
              &:last-child strong {
                border: none;
              }
            }
          }

          &.open ul {
            display: block;
          }
        }
      }
    }
  }
}


// the burger
// ===============================

button.burger-icon {
  display: none;
}

@include media-breakpoint-down(sm) {

  button.burger-icon {
    position: absolute;
    top: 1em;
    right: 1em;
    border: none;
    display: block;
    color: var(--font-color);
    outline: 0;
    background: transparent;
    margin: 0;
    padding: .5em .7em;
    z-index: 10;
    cursor: pointer;
    //width: 4em;

    // the "frame" 
    border: 1px solid var(--dark-red);
    outline: 4px solid rgba(0, 0, 0, 0.15);
    border-radius: 1px;



    svg {
      height: 30px;
      width: 30px;
      transform: translateX(1px);
    }

    path {
      transition: all .4s ease-in-out;
    }

    path#burger-upper {
      transform: rotate(0deg);
      transform-origin: 21px 0;
    }

    path#burger-lower {
      transform: rotate(0deg);
      transform-origin: 23px 17px;
    }

    path#burger-middle {
      opacity: 1;
    }

    &.close-button {
      position: absolute;
      border: 1px solid white;
      outline: 4px solid rgba(255, 255, 255, 0.15);
      color: var(--weiss);

      path#burger-upper {
        transform: translateX(-4px) rotate(-45deg);
      }

      path#burger-lower {
        transform: translateX(-4px) rotate(45deg);
      }

      path#burger-middle {
        opacity: 0;
      }
    }
  }
}