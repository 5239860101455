// Le Footer



footer {
  //padding-top: 3em;
  padding-bottom: 0em;
  align-items: end;
  position: relative;
  overflow: hidden;
  font-size: var(--step--1);

  .footer-container--upper {
    background: var(--hellgrau);
  }

  p,
  address,
  a {
    text-decoration: none;
    font-size: var(--step--1);
  }

  h3 {
    padding-top: 0.5em;
    font-size: 1.2em;
  }

  .footer-grid {
    grid-template-rows: auto auto;
    align-items: center;
    padding-top: var(--space-m-l);
    padding-bottom: var(--space-s-m);
  }

  .footer__logo {
    grid-column: 2 / 8;

    img {
      width: 100%;
      max-width: 640px;
      max-height: auto;
      mix-blend-mode: multiply;
    }
  }

  .footer__grossloge {
    grid-column: 11 / -2;

    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
    }

    img {

      @include media-breakpoint-down(sm) {
        max-width: 180px;
      }
    }
  }


  .footer__adresse {
    display: flex;
    justify-content: center;

    p {
      color: var(--font-color);
    }
  }


  .footer__copyright {
    grid-column: 2 / 6;
    grid-row: 2;

    p {
      padding: 0.7em 0;
      margin: 0;
    }
  }

  .footer__navigation {
    grid-column: 6 / -2;
    grid-row: 2;

    nav ul {
      margin: 0 -1em;
      text-align: right;

      li {
        display: inline-block;
        margin: 0;
        padding: 0 1em;

        a {
          color: var(--font-color);
          text-decoration: none;
          padding: 0.7em 0;
          display: block;
          font-weight: 400;

          &:hover {
            color: var(--dark-red);
          }
        }
      }
    }
  }
}

@media (max-width:768px) {
  footer {

    address {
      padding-left: 92px;

      a {
        color: inherit;
      }
    }

    .footer__copyright {
      grid-column: 2 / -2;
      grid-row: 2;
    }

    .footer__navigation {
      grid-column: 2 / -2;
      grid-row: 2;

      nav ul {
        text-align: center;
      }

    }

    .footer__copyright {
      text-align: center;
      margin-top: var(--space-m);
    }
  }
}

.footer__cookie {
  text-align: center;
  padding-bottom: var(--space-s);

  p {
    color: var(--font-color);
  }
}