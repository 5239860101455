/* the red well on the homepage */


.banner {
    background: #930000 url('../images/corona-banner.png') no-repeat left top;
    background-size: contain;
    padding: 2em 2em;
    margin-bottom: 2em;

    h2,
    p {
        color: var(--weiss);
    }

    h2 {
        margin-bottom: .5em;
    }

    @include media-breakpoint-up(md) {
        padding: 3em 5em;
        margin-bottom: 4em;
    }
}