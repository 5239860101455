// main navigation DESKTOP

@media screen and (min-width: 768px) {

  .header__navigation {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 auto;
    text-align: right;
    padding: 0;
    margin: 0;
    transition: all .2s;

    .nav-inner__upper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 1em;
    }

    nav {
      padding-left: 1em;
      display: flex;
      justify-content: flex-end;


      // Ebene 1

      ul {
        position: relative;
        flex: 1 1 auto;
        text-align: right;
        padding: 0;
        margin: 0 -15px;
        display: flex;
        list-style: none;

        li {
          position: relative;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          margin: 0;
          padding: 0;
          color: var(--schwarz);
          transition: all .15s ease-out;



          &:hover {

            //color: var(--weiss);
            >a {
              border-color: var(--dark-red);
            }
          }

          a,
          strong {
            color: inherit;
            text-decoration: none;
            font-weight: 400;
            font-size: var(--step--1);
            border-bottom: 2px solid rgba(0, 0, 0, 0);
            padding: .2em 0.1em;
            margin: 0 .6em;
            display: inline-block;
            transition: all .2s ease-out;
            hyphens: none;
            white-space: nowrap;

            @media screen and (min-width:1024px) {
              font-size: var(--step-0);
            }
          }

          &.active a,
          &.active>strong,
          &.open>a {
            //color: var(--weiss);
            border-color: var(--dark-red);
          }

          .more {
            width: .6em;
            height: .6em;
            cursor: pointer;
            z-index: 212;
            display: flex;
            align-content: center;
            justify-content: center;
            transform: rotate(90deg);

            svg {
              height: 14px;
              width: 14px;
            }
          }

          // the login link

          &.login {
            border: 1px solid var(--dark-red);
            outline: 4px solid rgba(0, 0, 0, 0.15);
            margin: 0 0.7em;
            padding: 0 0.7em;
            border-radius: 1px;

            &:hover {

              background: var(--himbeer);

              a {
                border-color: transparent;
                color: white;
              }
            }

            a {
              font-weight: 600;
              font-size: .9em;
            }
          }

          // Ebene 2

          ul {
            position: absolute;
            background: var(--weiss);
            box-shadow: var(--box-shadow) var(--box-shadow-color);
            padding: 1em 1.4em 1em 1em;
            right: auto;
            left: .2em;
            top: 3.2em;
            opacity: 0;
            visibility: hidden;
            //transition: opacity 0.2s linear, visibility 0.2s linear;
            transition: all .2s ease-out;
            // we're delaying the transition BACK a little more... this does the magic of NOT loosing focus that easy.
            transition-delay: .2s;
            //display: grid;
            display: block;
            gap: 0 3em;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-auto-columns: auto;
            grid-auto-flow: column;
            border-radius: var(--border-radius);
            z-index: 1;

            li {
              min-width: 10em;
              margin: 0;
              padding: 0;
              display: inline-block;


              a,
              strong {
                font-weight: 500;
                padding: 0.5em 0 .5em .5em;
                margin: 0;
                display: flex;
                border: 0;
                //border-top: 1px solid var(--dunkelgrau);
                text-align: left;
                border-left: 1px solid transparent;

                svg {
                  height: 1.6em;
                  width: 1.6em;
                  flex: 0 0 2.3em;
                }

                &:hover {
                  //background: #fff;
                  color: var(--blue-darker);
                }
              }



              &.active>strong {
                color: var(--brand-blue);
                border-color: var(--brand-blue);
                font-weight: bold;
                ;
              }
            }
          }

          // "Leistungen" Navigationspunkt: rechts angeschlagen
          &:nth-child(2) ul {
            right: 30px;
            left: auto;
          }
        }
      }
    }

    /* Drop-Down Navigation */
    ul li:hover>ul {
      visibility: visible;
      opacity: 1;
      top: 3.5em;
      // delaying the display of the submenu
      transition-delay: .1s;
    }
  }
}


//

// Setting the parent`` active state