// image styles

.image-framed {
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow) var(--box-shadow-color);
    background: white;
}

.image-padded {
    padding: 1em;
}

.image-bigger {
    transform: scale(1.0) translateY(1.1em);
    margin-top: 3em;
    margin-bottom: 3em;

    @include media-breakpoint-up(xs) {
        transform: scale(1.1) translateY(1.1em);

    }
}

.image-bigger--negative-margin {
    margin-bottom: -3em;
}

// Utilities:

.no-bottom-margin {
    margin-bottom: 0;
}

.no-bottom-padding {
    padding-bottom: 0;
}

.pull-up-after-skewed {
    margin-top: -10vw;

    @include media-breakpoint-up(sm) {
        margin-top: -12em;
    }

    @include media-breakpoint-up(lg) {
        margin-top: -14em;
    }
}

.pull-up-extreme-mobile {
    @include media-breakpoint-down(sm) {
        margin-top: -30vw;
    }
}

.negative-margin-content {
    position: relative;
    z-index: 1;

    img {
        margin-top: -10em;
        margin-bottom: -14em;

        @include media-breakpoint-up(sm) {
            margin-top: -12em;
        }
    }

}

.negative-bottom-margin {
    margin-bottom: -10em;
}

.small-bottom-margin {
    margin-bottom: 2em;
}

.medium-bottom-margin {
    margin-bottom: 3em;

    @include media-breakpoint-up(sm) {
        margin-bottom: 5em;
    }
}

.large-bottom-margin {
    margin-bottom: 6em;

    @include media-breakpoint-up(sm) {
        margin-bottom: 10em;
    }
}

.centered {
    display: flex;
    justify-content: center;
}

// for dark sections with negative margins on prev. section

.small-top-padding {
    padding-top: 5em;
}

.medium-top-padding {
    padding-top: 13em; // 10em is default on "skewed"
}

.img-padded {
    padding: 30px;
}