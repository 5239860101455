// Le Kalender


.event-list {
    border-top: 1px solid var(--linien-grau);
    padding: 0;
    margin: 2em 0 15px 0;
}

.event {
    padding: var(--space-m) 0 var(--space-2xs);
    margin: 0;
    list-style: none;
    border-bottom: 1px solid var(--linien-grau);

    &__date-location-container {
        display: flex;
        justify-content: space-between;
        font-size: 90%;
        flex-wrap: wrap;
    }

    &__date-time {
        margin: 0;
        padding: 0;
    }

    &__date {
        font-weight: 600;
        color: var(--dark-red);

    }

    &__time {}

    &__location {
        border: 1px solid var(--dark-red);
        padding: 0.1em .5em;
        background: var(--himbeer);
        color: var(--weiss);
        border-radius: 3px;
        font: 500 var(--step--1)/1.4 var(--copy-font);

        &.Logenhaus {
            border: 1px solid var(--linien-grau);
            background: var(--hellgrau);
            color: var(--mittelgrau-font);
        }
    }

    &__title {
        margin: 0;
        padding: 0 0 var(--space-xs) 0;
        //font-size: 1.4rem;
    }

}