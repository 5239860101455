// Le Header

header.header {
  //color: var(--brand-blue);

  position: absolute;
  top: auto;
  width: 100%;
  z-index: 100;
  z-index: 4;

  .header__inner {
    margin: 0;
    padding: 0 1em;
    z-index: 1;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 5em;
    align-items: center;
  }

  .header__logo {
    //padding-bottom: 10px;
    background: var(--hellgrau);
    padding-right: 3.8em;
    transform: translateX(-10px);

    img {
      mix-blend-mode: multiply;
    }

    img {
      max-width: 340px;
      //max-height: 60px;
    }
  }

  @include media-breakpoint-up(sm) {
    align-items: end;

    .header__inner {
      padding: 0 2em;
    }

    .header__logo {

      img {
        max-width: 240px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    position: absolute;

    .header__inner {
      padding: 0.6em 1.4em 0;
    }

    .header__logo {
      img {
        max-width: 430px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .header__inner {
      padding: .6em 1.4em 0;
    }

    .header__logo {
      img {
        max-width: 450px;
      }
    }
  }
}