// definieng the Conatiner
main {
  overflow: hidden; // used to be "x"-only - lets see it this backfires..
}

.container,
.grid {  
  padding-right: var(--min-margin);
  padding-left: var(--min-margin);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container,
  .grid {
    max-width: none;
    padding-right: 0;
    padding-left: 0;
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: minmax(clamp(var(--min-margin), 7vw, 50%), calc(50vw - 43rem)) repeat(12, 1fr) minmax(clamp(var(--min-margin), 7vw, 50%), calc(50vw - 43rem));
  }
}


// basic structures

.box--black {
  grid-column: 5 / 13;
  padding-top: 4em;
  padding-bottom: 4em;

  @include media-breakpoint-up(lg) {
    grid-column: 7 / 13;
    padding-top: 12em;
    padding-bottom: 6em;
  }
}

figure {
  margin: 2em 0 2em;

  @include media-breakpoint-up(sm) {
    margin: 2em 0 3em;
  }
}

.img-icon {
  width: auto;
  height: auto;
  display: block;
  padding: 2em 0 1em 0;
}

.figure-element-8-4 {
  :nth-child(1) {
    grid-column: 1 / 9;
  }

  :nth-child(2) {
    grid-column: 9 / 13;
  }
}

.figure-element-4-8 {
  :nth-child(1) {
    grid-column: 1 / 5;
  }

  :nth-child(2) {
    grid-column: 5 / 13;
  }
}

.text-element-7-4 {
  :nth-child(1) {
    grid-column: 1 / 8;
  }

  :nth-child(2) {
    grid-column: 9 / 13;
  }
}

.text-element-6-5 {
  :nth-child(1) {
    grid-column: 1 / 7;
  }

  :nth-child(2) {
    grid-column: 8 / 13;
  }

  &--reverse {
    :nth-child(1) {
      grid-column: 7 / 13;
      grid-row: 1;
    }

    :nth-child(2) {
      grid-column: 1 / 6;
      grid-row: 1;
    }
  }
}

.text-element-5-6 {
  :nth-child(1) {
    grid-column: 1 / 7;
  }

  :nth-child(2) {
    grid-column: 8 / 13;
  }
}

.text-element-4-7 {
  :nth-child(1) {
    grid-column: 1 / 5;
  }

  :nth-child(2) {
    grid-column: 6 / 13;
  }
}


.text-element-5-3-3 {
  :nth-child(1) {
    grid-column: 1 / 6;
  }

  :nth-child(2) {
    grid-column: 7 / 10;
  }

  :nth-child(3) {
    grid-column: 10 / 13;
  }
}

.text-element-6-6 {
  :nth-child(1) {
    grid-column: 1 / 7;
  }

  :nth-child(2) {
    grid-column: 7 / 13;
  }
}

.text-element-3-3-3 {
  :nth-child(1) {
    grid-column: 1 / 5;
  }

  :nth-child(2) {
    grid-column: 5 / 9;
  }

  :nth-child(3) {
    grid-column: 9 / 13;
  }
}

.text-element-2-8-2 {
  :nth-child(1) {
    grid-column: 3 / 11;
  }
}

.text-element-1-10-1 {
  :nth-child(1) {
    grid-column: 2 / 12;
  }
}

.text-element-2-5-3-2 {
  :nth-child(1) {
    grid-column: 3 / 8;
  }

  :nth-child(2) {
    grid-column: 8 / 11;
  }
}

.text-element-2-4-4-2 {
  :nth-child(1) {
    grid-column: 3 / 8;
  }

  :nth-child(2) {
    grid-column: 8 / 12;
  }
}



.grid-avatar {
  padding-top: 3.5em;

  >div {
    grid-column: span 4;
    padding: 1em 1em;
    text-align: center;
    margin-top: -3em;

    @include media-breakpoint-up(sm) {
      margin-top: -3.5em;
    }

    img {
      max-width: 220px;
      margin-bottom: 1em;
    }
  }
}


.grid-three-teaserboxes {
  >div {
    grid-column: span 4;

    img {
      margin-bottom: 1em;
    }
  }
}

.grid-avatar-kontakt {
  >div {
    grid-column: span 5;
    padding: 1em 0 1em 1em;
    text-align: left;
    display: flex;
    align-items: center;

    img {
      max-width: 180px;
      margin-right: 20px;
    }
  }
}

// styling the elements



.box--black__inner {
  background: var(--blue-dark);
  color: white;
  padding: 2.2em 1.8em 0.8em;

  h3 {
    padding-top: 0;
  }
}


.full-bleed-background {
  background-size: cover;
  padding: 8em 0 2em 0;
}

main {
  padding-top: 0;

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}


section {
  padding-bottom: var(--space-l-xl);
  margin-bottom: var(--space-l-xl);

  /* @include media-breakpoint-up(md) {
    padding-bottom: 2em;
    margin-bottom: 3em;
  } */
}

.section--gray {
  background: var(--hellgrau);
  padding-top: 4em;
  margin-bottom: 4em;
  padding-bottom: 4em;
}


.section--background_image {
  position: relative;
  padding: 3em 0 #{calc(3em + 7em)};
  margin-bottom: 4em;
  color: white;
  //background: url(/img/schmuckbilder/schmuckbild_1.jpg) no-repeat center;
  background-size: cover;

  &:before {
    content: "";
    //background: var(--overlay-light-blue);
    opacity: .4;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7em;
    mix-blend-mode: overlay;
  }

  &:after {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:#{calc(100% - 7em)};
  }

  &>div {
    position: relative;
    z-index: 1;
  }

  // startseite special
  &--reverse {
    padding: #{calc(4em + 7em)} 0 4em;

    &:before {
      background: white;
      bottom: auto;
      left: 0;
      top: 0;
    }

    &:after {
      background: rgba(0, 0, 0, 0.85);
      bottom: 0;
      left: 0;
      top: auto;
    }
  }
}

// grid advantages Startseite

.grid--advantages {
  align-items: center;
  margin-bottom: 5em;

  @include media-breakpoint-up(sm) {
    margin-bottom: 9em;
  }

  //transform-origin: left center;

  >div:nth-child(1) {
    grid-column: 3 / 6;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }

    svg {
      width: 8em;
      height: 8em;
      max-width: 100%;

      @include media-breakpoint-up(sm) {
        height: 12em;
        width: 12em;
      }
    }
  }

  >div:nth-child(2) {
    grid-column: 6 / 11;
  }
}

// smaller advantages Grid on subpage::after

.grid-advantages--small {
  grid-gap: 50px;

  &__item {
    grid-column: span 6;
    padding-bottom: 2em;

    @include media-breakpoint-up(md) {

      display: flex;
    }

    div:nth-child(1) {
      flex: 0 0 26%;

      svg {
        width: 7em;
        height: 7em;
      }
    }

    div:nth-child(2) {
      flex: 1 1 auto;
    }
  }
}


// kogos auf der Startseite
.grid__logos {
  padding-top: 2em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.logo-item {
  padding: .5em;

  img {
    width: auto;
  }
}

@include media-breakpoint-up(sm) {
  .grid__logos {
    grid-template-columns: repeat(5, 1fr);
  }
}


// Industries auf der Startseite
ul.grid__matrix {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0;
  //margin: 15px;
  grid-gap: 1px;
  list-style: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow) var(--box-shadow-color);
  background-color: var(--grau);
  overflow-x: hidden;

  li {
    grid-column: span 4;
    padding: 1.5em;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--blue-darker);
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.2;
    min-height: 4em;
    text-align: center;
    hyphens: none;

    @include media-breakpoint-up(xs) {
      padding: 4em 1em;
      grid-column: span 2;
      min-height: 8em;
    }

    @include media-breakpoint-up(md) {
      grid-column: span 1;
      padding: 4em 1em;
    }
  }
}


// auskragendes Image

@include media-breakpoint-up(sm) {

  .section--bleeding-image {
    position: relative;
    z-index: 1;
  }

  .bleeding-image__holder {
    position: absolute;
    width: 180%;
    height: auto;
    top: -6em;
    left: 0;
  }

  .bleeding-image__wrapper {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 24em;

    @include media-breakpoint-up(md) {
      min-height: 26em;
    }

    @include media-breakpoint-up(xl) {
      min-height: 32em;
    }

  }

}

// der Gerät Startseite

.der-geraet {
  text-align: center;

  img {
    padding: 2em 0;
  }
}



// map

.map-container {
  iframe {
    width: 100%;
    border: none;
    min-height: 40vw;
  }
}

// awards img

.awards-logo {
  padding: 3em 0;
  max-width: 680px;
}