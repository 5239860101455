// global Variables
// `custom-properties` wherever possible in this project

:root {

        // Farben
        --dark-red: #940000;
        --himbeer: #CA4154;
        --akzentfarbe: #ff9900;
        --schwarz: #000;
        --weiss: #fff;
        --hellgrau: #f7f7f7;
        --linien-grau: #d1d1d1;
        --grau: #e6e6e6;
        --mittelgrau: #808080;
        --mittelgrau-font: #707070;

        --font-color: #222;
        // Fonts
        --copy-font: 'Poppins', sans-serif;
        --headline-font: 'Cinzel', serif;

        // some general Values
        --box-shadow: 0 0px 42px;
        --box-shadow-color: rgba(0, 0, 0, .1);
        --border-radius: 19px;
}

// These are Vars for the bootstrap-grid – even though we do not use the grid itself,
// the breakpoints und the max-width declaration for the `.container` is pretty handy.


// Grid breakpoints
//
// Define the maximum width of `.container` for different screen sizes.


$grid-breakpoints: (xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px) !default;


        /* The container and grid min width */
:root {
        --min-margin: 15px;
}
//* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1680,17,1.414,6,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
        --step--2: clamp(0.5314rem, 0.7328rem + -0.1918vw, 0.6944rem);
        --step--1: clamp(0.7514rem, 0.8526rem + -0.0964vw, 0.8333rem);
        --step-0: clamp(1rem, 0.9853rem + 0.0735vw, 1.0625rem);
        --step-1: clamp(1.2rem, 1.1289rem + 0.3557vw, 1.5024rem);
        --step-2: clamp(1.44rem, 1.279rem + 0.8051vw, 2.1244rem);
        --step-3: clamp(1.728rem, 1.4278rem + 1.501vw, 3.0038rem);
        --step-4: clamp(2.0736rem, 1.5621rem + 2.5575vw, 4.2474rem);
        --step-5: clamp(2.4883rem, 1.6607rem + 4.1383vw, 6.0059rem);
        --step-6: clamp(2.986rem, 1.6904rem + 6.478vw, 8.4923rem);
      }

      /* @link https://utopia.fyi/space/calculator?c=320,16,1.2,1680,17,1.414,6,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
        --space-3xs: clamp(0.25rem, 0.25rem + 0vw, 0.25rem);
        --space-2xs: clamp(0.5rem, 0.4853rem + 0.0735vw, 0.5625rem);
        --space-xs: clamp(0.75rem, 0.7353rem + 0.0735vw, 0.8125rem);
        --space-s: clamp(1rem, 0.9853rem + 0.0735vw, 1.0625rem);
        --space-m: clamp(1.5rem, 1.4706rem + 0.1471vw, 1.625rem);
        --space-l: clamp(2rem, 1.9706rem + 0.1471vw, 2.125rem);
        --space-xl: clamp(3rem, 2.9559rem + 0.2206vw, 3.1875rem);
        --space-2xl: clamp(4rem, 3.9412rem + 0.2941vw, 4.25rem);
        --space-3xl: clamp(6rem, 5.9118rem + 0.4412vw, 6.375rem);
      
        /* One-up pairs */
        --space-3xs-2xs: clamp(0.25rem, 0.1765rem + 0.3676vw, 0.5625rem);
        --space-2xs-xs: clamp(0.5rem, 0.4265rem + 0.3676vw, 0.8125rem);
        --space-xs-s: clamp(0.75rem, 0.6765rem + 0.3676vw, 1.0625rem);
        --space-s-m: clamp(1rem, 0.8529rem + 0.7353vw, 1.625rem);
        --space-m-l: clamp(1.5rem, 1.3529rem + 0.7353vw, 2.125rem);
        --space-l-xl: clamp(2rem, 1.7206rem + 1.3971vw, 3.1875rem);
        --space-xl-2xl: clamp(3rem, 2.7059rem + 1.4706vw, 4.25rem);
        --space-2xl-3xl: clamp(4rem, 3.4412rem + 2.7941vw, 6.375rem);
      
        /* Custom pairs */
        --space-s-l: clamp(1rem, 0.7353rem + 1.3235vw, 2.125rem);
      }