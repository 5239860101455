// Les Sections...



.page-header {
  //padding: 10em 0 3em;
  padding:10em 0 var(--space-xl);
  background: var(--hellgrau);

  &__inner {
    grid-column: 2 / 14;
    display: grid;
    grid-template-columns: 38% 4% auto;
  }

  &__image {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  &__headline {
    grid-column: 2 / 4;
    grid-row: 1;

    h1 {
      font-size: 8.5vw;
      font-family: var(--headline-font);
      color: var(--font-color);
      text-transform: uppercase;
      line-height: 1;
      font-weight: 500;
      margin: .50em 0;

      small {
        display: block;
        font-size: 20%;
        font-family: var(--copy-font);
        text-transform: none;
        margin-top: 1.2em;
      }

      @include media-breakpoint-up(sm) {
        font-size: clamp(28px, 6.6vw, 124px);
        font-weight: 400;
      }
    }
  }
}

.page-header--content {
  padding: 6em 0 1em;
  background: var(--hellgrau);

  &__headline {
    grid-column: 2 / 14;

    h1 {
      //font-size: 8.5vw;
      font-family: var(--headline-font);
      color: var(--font-color);
      text-transform: uppercase;
      line-height: 1;
      font-weight: 400;
      text-align: center;
      position: relative;

      span {
        font-size: 0.4em;
        font-weight: 500;
        line-height: 2;
        position: relative;
        display: block;
      }

      @include media-breakpoint-up(sm) {

        span {
          display: inline-block;
          transform: translateY(-.5em);
        }
      }
    }
  }

}

// All longform contents

.content {
  * {
    grid-column: 4 / 12;
  }

  figure {
    grid-column: 3 / 13;
  }

}